import { CheckoutLoginParams, CheckoutLoginResponse } from "../../../packages/mkm-api/src/types";
import useMiddlewareCheck from "./useMiddlewareCheck";
import { toRefs } from "@vueuse/core";

type UseCheckoutState = {
  loadingLogin: boolean | undefined;
  errorLogin: number | undefined;
  loginRetry: number;
};

export const useCheckout = () => {
  const state = useState<UseCheckoutState>(`useCheckout-error`, () => ({
    loadingLogin: undefined,
    errorLogin: 0,
    loginRetry: 0,
  }));
  const middlewareCheck = useMiddlewareCheck();

  const login = async ({ params }: CheckoutLoginParams) => {
    try {
      state.value.loadingLogin = true;
      const { data: result } = await useAsyncData<CheckoutLoginResponse>("checkoutLogin", () =>
        useSdk().mkmAPI.checkoutLogin({ params }),
      );

      if (result.value?.status === 200) {
        return result.value.redirectTo;
      }

      if (result.value?.status) {
        throw new Error(JSON.stringify({ statusCode: result.value.status }));
      }

      return null;
    } catch (error) {
      middlewareCheck.checkIfSessionRelatedIssue(error);

      if (error instanceof Error) {
        throw error;
      }

      throw new Error(JSON.stringify({ statusCode: 500 }));
    } finally {
      state.value.loadingLogin = false;
    }
  };

  return {
    login,
    ...toRefs(state.value),
  };
};

export default useCheckout;
